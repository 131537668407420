import {
  ApiService,
  DCAbstractStore,
  DownloadConfig,
  DownloadFileConfig,
  FileKey,
  Loadable,
  LoggerService,
} from '@framework';
import { Observable, concat, range, throwError, timer } from 'rxjs';
import { concatMap, delay, mergeMap, retryWhen, zip } from 'rxjs/operators';
import { LoadingState } from '@domain';
import { interpolate } from '@utils';
import { tapResponse } from '@ngrx/operators';
import { inject } from '@angular/core';
import { downloadDocumentOptDi } from './providers/download-document.di';

export interface State extends Loadable {
  queue: number;
}

const defaultState: State = {
  callState: LoadingState.INIT,
  queue: 0,
};

export abstract class DownloadDocumentStore extends DCAbstractStore<State> {
  private readonly downloadDocumentUrl = inject(downloadDocumentOptDi);

  private readonly RETRY_COEFFICIENT = 1.4;
  private readonly FIRST_CALL_DELAY = 500;
  private readonly RANGE_START = 1;
  private readonly RANGE_COUNT = 20;

  protected constructor(
    protected readonly api: ApiService,
    private readonly logger: LoggerService,
  ) {
    super(defaultState);
  }

  abstract fetchFile$(filter?: any): Observable<FileKey>;

  readonly addQueue = this.updater((state) => {
    let queue = state.queue + 1;
    return {
      ...state,
      queue,
      callState: LoadingState.LOADING,
    };
  });
  readonly removeQueue = this.updater((state) => {
    let queue = state.queue - 1;
    return {
      ...state,
      queue,
      callState: queue === 0 ? LoadingState.LOADED : LoadingState.LOADING,
    };
  });
  readonly download = this.effect((filter$: Observable<DownloadConfig>) =>
    filter$.pipe(
      concatMap((filter) => {
        this.addQueue();
        const params = filter.callbackFilter;
        return this.fetchFile$(params).pipe(
          delay(1000),
          mergeMap((fileKey) =>
            this.downloadFile(fileKey, filter?.config).pipe(
              retryWhen((errors) =>
                errors.pipe(
                  zip(range(this.RANGE_START, this.RANGE_COUNT), (_, j) => j),
                  mergeMap((count: number) => timer(this.FIRST_CALL_DELAY * Math.pow(count, this.RETRY_COEFFICIENT))),
                  (o) => concat(o, throwError('Max retries exceeded.')),
                ),
              ),
            ),
          ),
          tapResponse(this.removeQueue, (e: Error) => {
            this.logger.error('Error downloading file', e);
            this.updateError(e.message);
          }),
        );
      }),
    ),
  );

  private downloadFile(fileKey: FileKey, config: DownloadFileConfig | undefined) {
    const url = interpolate(this.downloadDocumentUrl, {
      fileKey,
    });
    return this.api.downloadFile(url, config?.fileName, (response) => {
      if (response.status !== 200) {
        throw new Error(`Bad Download Response`);
      }
    });
  }
}
