<div class="flex h-full w-full p-5 pt-0">
    <div class="relative flex w-full flex-col items-center justify-center rounded-2xl bg-gray-100">
        <div class="absolute top-0 flex w-full justify-center">
            <span class="absolute -top-[60%] text-[15rem] font-black text-gray-50">404</span>
            <ui-svg type="illustration" name="Broken Bulb" />
        </div>
        <div class="mt-20 text-md font-semibold text-blue-gray-800">
            Ať svítím jak svítím, možná praskla žárovka, ale tato stránka tu není ...
        </div>
        <ui-button
            styleClass="ui-button-secondary mt-7"
            label="Půjdu radši na Dashboard"
            (onClick)="navigateToDashboard()"
        />
    </div>
</div>
